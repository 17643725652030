import { Profession } from '@discretize/gw2-ui-new';
import { Box, Button, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import { graphql, Link, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Logo from '../assets/svg/Logo';

import Layout from '../components/Layout';
import WigglyDown from '../components/navigation/WigglyDown';
import SeoHeader from '../components/SeoHeader';
import { getInviteLink } from '../state/discord';

const layout = {
  disableContainer: true,
  disableBackground: true,
  fabNavigation: true,
};

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minHeight: '33vw',
    '&:not(:last-child)': {
      borderBottom: `2px solid ${theme.palette.divider}`,
    },
  },
  wrapperCenter: {
    background:
      'radial-gradient(rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%)',
    [theme.breakpoints.down('md')]: {
      background:
        'radial-gradient(rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%)',
    },
    justifyContent: 'center',
    textAlign: 'center',
    height: '100vh',
  },
  wrapperBuilds: {
    background:
      'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,0.9640231092436975) 70%, rgba(0,0,0,0) 100%)',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100vh',
  },
  wrapperBigLeft: {
    background:
      'linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,0.75) 40%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 60%)',
    justifyContent: 'flex-start',
    textAlign: 'left',
    height: '100vh',
  },
  wrapperBigRight: {
    background:
      'linear-gradient(to left, rgba(0,0,0,1) 0%, rgba(0,0,0,0.75) 40%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 60%)',
    justifyContent: 'flex-end',
    textAlign: 'left',
    height: '100vh',
  },
  wrapperLeft: {
    background:
      'linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,0.75) 40%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 60%)',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  wrapperRight: {
    background:
      'linear-gradient(to left, rgba(0,0,0,1) 0%, rgba(0,0,0,0.75) 40%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 60%)',
    justifyContent: 'flex-end',
    textAlign: 'left',
  },
  content: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },
  contentMain: {
    padding: theme.spacing(2),
    maxWidth: 600,
  },
  contentInner: {
    padding: `${theme.spacing(10)} ${theme.spacing(2)}`,
    margin: '0 auto',
    maxWidth: 480,
  },
  image: {
    position: 'absolute !important' as any,
    width: '100%',
    height: '100%',
    zIndex: -1,
    '& > picture > img': {
      objectPosition: 'center bottom !important',
    },
  },
  button: {
    padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
    marginBottom: theme.spacing(1.5),
    '&:not(:last-child)': {
      marginRight: theme.spacing(1.5),
    },
  },
  bigIcon: {
    fontSize: '80px',
  },
  zoom: {
    transition: 'transform .2s',
    margin: '0 auto',
    '& *:hover': { transform: 'scale(1.3, 1.3)' },
  },
  text: {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9rem',
    },
    marginBottom: theme.spacing(1),
  },
  frontpagebuildText: { whiteSpace: 'nowrap' },
}));

const FEATURED_BUIDLS: {
  to: string;
  label: string;
  name: React.ComponentProps<typeof Profession>['name'];
}[] = [
  {
    to: '/builds/revenant/power-renegade/',
    label: 'Power Renegade',
    name: 'Renegade',
  },
  {
    to: '/builds/engineer/power-scrapper',
    label: 'Power Scrapper',
    name: 'Scrapper',
  },
  {
    to: '/builds/ranger/power-soulbeast/',
    label: 'Power Soulbeast',
    name: 'Soulbeast',
  },
  {
    to: '/builds/thief/condi-specter/',
    label: 'Condi Specter',
    name: 'Specter',
  },
  {
    to: '/builds/necromancer/condi-harbinger/',
    label: 'Condi Harbinger',
    name: 'Harbinger',
  },
  {
    to: '/builds/mesmer/heal-chronomancer/',
    label: 'Heal Chronomancer',
    name: 'Chronomancer',
  },
];

interface FrontPageBuildProps {
  to: string;
  label: string;
  name: React.ComponentProps<typeof Profession>['name'];
  classes: Record<string, string>;
}

function FrontPageBuild({ classes, to, name, label }: FrontPageBuildProps) {
  return (
    <Box display="flex" flexDirection="column">
      <Link to={to}>
        <Profession
          className={classNames(classes.bigIcon, classes.zoom)}
          name={name}
          disableText
          aria-label={label}
        />
      </Link>
      <Typography
        variant="caption"
        gutterBottom
        className={classes.frontpagebuildText}
      >
        {label}
      </Typography>
    </Box>
  );
}

function IndexPage({ data }: { data: Queries.IndexPageQuery }) {
  const { classes } = useStyles();
  const discordInvite = useSelector(getInviteLink);

  const {
    heroSunqua,
    heroArkkarena,
    heroSunqua2,
    heroShatteredObservatory,
    heroDeepstone,
  } = data;

  return (
    <>
      <Link id="top" to="#top" aria-label="top" />

      <div className={classNames(classes.wrapper, classes.wrapperCenter)}>
        <div className={classNames(classes.content, classes.contentMain)}>
          <Logo />

          <Typography variant="h6" color="textSecondary" gutterBottom>
            International high-end fractal guild
          </Typography>
        </div>
        {heroArkkarena?.childImageSharp && (
          <GatsbyImage
            image={heroArkkarena.childImageSharp.gatsbyImageData}
            className={classes.image}
            alt=""
          />
        )}
        <WigglyDown />
      </div>

      <div className={classNames(classes.wrapper, classes.wrapperBigLeft)}>
        <div className={classes.content}>
          <div className={classes.contentInner}>
            <Typography variant="h5" gutterBottom>
              About Discretize
            </Typography>

            <Typography variant="body1" className={classes.text}>
              With its infamous roster, Discretize has established a high-end
              fractal guild that serves as a beacon for the Guild Wars 2 fractal
              community. Since 2017 we have shared knowledge to inspire and
              elucidate the game for players to grow and discover new depths of
              fractal gameplay. With our detailed builds and guides, we provide
              invaluable resources for new players and veterans alike. We
              continue our work to encourage a more well-informed player-base
              that can spark innovation and progress within the community.
            </Typography>
          </div>
        </div>

        {heroSunqua2?.childImageSharp && (
          <GatsbyImage
            image={heroSunqua2.childImageSharp.gatsbyImageData}
            className={classes.image}
            alt=""
          />
        )}
      </div>

      <div className={classNames(classes.wrapper, classes.wrapperBuilds)}>
        <div className={classes.content}>
          <Typography variant="h5" gutterBottom>
            Featured Builds
          </Typography>

          <Grid container>
            {FEATURED_BUIDLS.map(({ to, label, name }) => (
              <Grid item xs={6} md={4} xl={2}>
                <FrontPageBuild
                  to={to}
                  label={label}
                  name={name}
                  classes={classes}
                />
              </Grid>
            ))}
          </Grid>
        </div>

        {heroDeepstone?.childImageSharp && (
          <GatsbyImage
            image={heroDeepstone.childImageSharp.gatsbyImageData}
            className={classes.image}
            alt=""
          />
        )}
      </div>

      <div className={classNames(classes.wrapper, classes.wrapperBigRight)}>
        <div className={classes.content}>
          <div className={classes.contentInner}>
            <Typography variant="h5" gutterBottom>
              Game guides
            </Typography>

            <Typography variant="body1" className={classes.text}>
              Our guides are designed to improve your knowledge of game
              mechanics, professions and fractals.
            </Typography>

            <Typography variant="body1" className={classes.text}>
              We provide up-to-date character builds which contain all the
              information needed to play the desired profession to perfection.
              Our fractal guides aim to step up your daily runs by teaching you
              general meta strategies as well as advanced speed tactics.
            </Typography>

            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/guides"
              className={classes.button}
            >
              Guides
            </Button>

            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/builds"
              className={classes.button}
            >
              Builds
            </Button>

            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/fractals"
              className={classes.button}
            >
              Fractals
            </Button>
          </div>
        </div>
        {heroSunqua?.childImageSharp && (
          <GatsbyImage
            image={heroSunqua.childImageSharp.gatsbyImageData}
            className={classes.image}
            alt=""
          />
        )}
      </div>

      <div className={classNames(classes.wrapper, classes.wrapperBigLeft)}>
        <div className={classes.content}>
          <div className={classes.contentInner}>
            <Typography variant="h5" gutterBottom>
              Be part of something big
            </Typography>

            <Typography variant="body1" className={classes.text}>
              If you want to join us, please understand that we only accept
              experienced people with optimized gear and the ambition to clear
              daily fractals quickly. Check our application sheet for the full
              list of requirements.
            </Typography>

            <Typography variant="body1" className={classes.text}>
              If you would like to take part in our discussions, consider
              joining our Discord server with over 3000 people - it is public to
              everyone.
            </Typography>

            <Typography variant="body1" className={classes.text}>
              We have dedicated channels for fractal LFG, mechanics, profession
              and fractal discussions. This is also the best place to receive
              immediate support for any questions!
            </Typography>

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              component={Link}
              to="/apply"
            >
              Join us ingame
            </Button>

            {discordInvite && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                component={Link}
                to={discordInvite}
              >
                Join our Discord
              </Button>
            )}
          </div>
        </div>

        {heroShatteredObservatory?.childImageSharp && (
          <GatsbyImage
            image={heroShatteredObservatory.childImageSharp.gatsbyImageData}
            className={classes.image}
            alt=""
          />
        )}
      </div>
    </>
  );
}

export default function IndexPageWrapper({
  data,
  location,
}: PageProps<Queries.IndexPageQuery>) {
  return (
    <Layout {...layout} location={location}>
      <SeoHeader path={location.pathname} />

      <IndexPage data={data} />
    </Layout>
  );
}

export const query = graphql`
  query IndexPage {
    heroSunqua: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "hero_sunqua.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }

    heroSunqua2: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "hero_sunqua2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }

    heroShatteredObservatory: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "hero_shattered-observatory.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }

    heroArkkarena: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "hero_arkkarena.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }

    heroDeepstone: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "hero_deepstone.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
