import classNames from 'classnames';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    bottom: '0',
    left: '0',
    right: '0',
    position: 'absolute',
  },
  arrow: {
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: '0 1.5em',
    display: 'block',
    height: '0',
    margin: '1.5em auto',
    opacity: 0.4,
    textIndent: '-9999px',
    transformOrigin: '50% 50%',
    width: '0',
  },
  down: {
    animation: 'downarrow 0.6s infinite alternate ease-in-out',
    borderTop: `2em solid ${theme.palette.primary.main}`,
    '@keyframes downarrow': {
      '0%': { transform: 'translateY(0)', opacity: 0.4 },
      '100%': { transform: 'translateY(0.4em)', opacity: 0.9 },
    },
  },
}));

function WigglyDown({ className }: { className?: string }) {
  const { classes } = useStyles();
  return (
    <div className={classNames(className, classes.container)}>
      <span className={classNames(classes.down, classes.arrow)}>Down</span>
    </div>
  );
}

export default WigglyDown;
